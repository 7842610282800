enum BranchSettingType {
  ModifyCurrentKm = 1,
  FromEmail = 2,
  FromName = 3,
  LastIgnitionEndBuffer = 4,
  CarCreditCardActivationMinutes = 5,
  SupportedLanguages = 6,
  SubscriptionMinimumAge = 7,
  SubscriptionMinimumAgeCoMember = 8,
  SubscriptionMaxCoMembers = 10,
  CustomerBalanceMax = 11,
  SubscriptionCoMemberFee = 13,
  FreeFloatingManagement_EnergyLevelWarning = 14,
  FreeFloatingManagement_EnergyLevelDanger = 15,
  FreeFloatingManagement_ToMoveWarning = 16,
  FreeFloatingManagement_ToMoveDanger = 17,
  FreeFloatingManagement_ToWash = 18,
  FreeFloating_BlockTime = 19,
  FreeFloating_BlockLimitNumber = 20,
  FreeFloating_BlockLimitNbHours = 21,
  Invoice_AmountMinimumForInvoiceGeneration = 22,
  Billing_TransactionStateToExport = 23,
  Subscription_Identification_MobilePhoneRequired = 24,
  Subscription_PackageHistStartDate = 25,
  Billing_PackageRenewal_CurrentMonth = 26,
  Reservation_MinimumDurationToBill = 27,
  Subscription_GoogleTagManager_Id = 28,
  FreeFloating_VehicleInspectionFrequency = 29,
  FreeFloating_VehiclePriorityInspectionFrequency = 30,
  Billing_RealTimeEndDateBuffer = 31,
  PaymentProvider_PermitCustomerCanMakePayment = 32,
  CustomerBalanceOverdueAmount = 33,
  Subscription_Revival_TemplateId = 34,
  Subscription_Rejected_TemplateId = 35,
  Subscription_Converted_TemplateId = 36,
  Subscription_CoMemberInvitation_TemplateId = 37,
  Subscription_ForgotPassword_TemplateId = 9,
  Subscription_Final_TemplateId = 38,
  Subscription_FinalBievenuePromo_TemplateId = 39,
  CustomerBalanceDelay_GracePeriod = 40,
  CustomerBalanceMax_GracePeriod = 41,
  FreeFloatingManagement_WinterModeOn = 43,
  ErpExport_CustomerBillingExportTime = 44,
  ErpExport_ParkingProviderExportTime = 45,
  PaymentProvider_NbThreadForWithdrawal = 46,
  ErpExport_CustomerPaymentExportTime = 47,
  TransactionManager_OrphanPaymentCustomerId = 48,
  ErpExport_CustomerExportTime = 49,
  CustomInvoice_YearPrefix_Sequential = 50,
  TripEvent_MaxDistanceKmBeforeUpdatingLocalization = 51,
  CustomerInvoice_IgnoreEmailSent = 52,
  StationZoneDistanceBuffer = 53,
  Timezone = 54,
  DefaultCurrency = 55,
  DistanceUnit = 56,
  FreeFloating_BookingDurationLimitNbMinutes = 57,
}

export default BranchSettingType;
