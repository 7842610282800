import AuthenticatedServiceBase from "@reservauto/react-shared/services/AuthenticatedServiceBase";
import {
  FetchPromise,
  GetTimeZone,
  ServiceResponseError,
  StatusCode,
} from "@reservauto/react-shared/services/ServiceBase";
import UrlParams from "@reservauto/react-shared/UrlParams";
import appUrlParams from "../appUrlParams";
import authenticationWebViewService from "../frame/authenticationWebViewService";
import appSettings from "./appSettings";
import userFOStore from "./stores/userFOStore";

export default abstract class FrontOfficeServiceBase extends AuthenticatedServiceBase {
  protected get rootUrl(): string {
    return appSettings.RestAPIFrontOfficeUri;
  }

  protected requestWithTimeout<T, V>(
    params: {
      getTimeZone: GetTimeZone<V>;
      isRetrySafe?: boolean;
      onAbort?: () => void;
      request: (signal: AbortSignal) => Promise<Response>;
      timeoutSeconds: number | undefined;
    },
    isRetrying?: boolean,
  ): FetchPromise<T> {
    return super
      .requestWithTimeout<T, V>(params, isRetrying)
      .catch(async (ex) => {
        const authStatusCodes = [StatusCode.Forbidden, StatusCode.Unauthorized];
        if (
          userFOStore.isSignedIn() &&
          ex instanceof ServiceResponseError &&
          authStatusCodes.includes(ex.statusCode) &&
          !appUrlParams.isAuthenticationRetry
        ) {
          if (appUrlParams.isMobileAppWebView) {
            await authenticationWebViewService.requestNewAccessToken();

            return super.requestWithTimeout<T, V>(params, isRetrying);
          }

          const urlParams = new UrlParams();
          urlParams.set("isAuthenticationRetry", "1");
          urlParams.updateUrl();

          ex.retryAuthentication = true;

          throw ex;
        } else {
          throw ex;
        }
      });
  }
}
