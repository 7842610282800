import StateStoreBase from "@reservauto/react-shared/stores/StateStoreBase";
import userStore from "@reservauto/react-shared/stores/userStore";
import { User, UserProfile as OidcUserProfile } from "oidc-client-ts";
import { CustomerDetailDTO } from "../../areas/customer/dto";

export interface UserProfile extends OidcUserProfile {
  [claimKey: string]: unknown;
  branchId: string;
  displayName: string;
  idp: string;
  role: Array<string>;
  ui_locale: string;
  userType: string;
}

export interface UserInfo {
  details: CustomerDetailDTO | null;
  userProfile: UserProfile | null;
}

export class UserFOStore extends StateStoreBase<UserInfo> {
  private userInfo: UserInfo = {
    details: null,
    userProfile: null,
  };

  public get(): UserInfo {
    return this.userInfo;
  }

  public isSignedIn(): boolean {
    return userStore.isSignedIn();
  }

  public populate(authenticatedUser: User): UserProfile {
    const profile = authenticatedUser.profile as UserProfile;
    this.userInfo = {
      ...this.userInfo,
      userProfile: profile,
    };

    userStore.populate(authenticatedUser);
    this.notifySubscribers();

    return profile;
  }

  public async populateDetails(
    getDetails: () => Promise<CustomerDetailDTO>,
  ): Promise<void> {
    const details = await getDetails();

    this.userInfo = {
      ...this.userInfo,
      details: details,
    };
    this.notifySubscribers();
  }
}

const userFOStore = new UserFOStore();
export default userFOStore;
