type Environments =
  | "Development"
  | "Local"
  | "PreProduction"
  | "Production"
  | "QA";

interface AppSettings {
  CommunautoIdSrv: string;
  Env: Environments;
  Nonce: string;
  ReservautoFrontOfficeIdentityProviderUri: string;
  ReservautoLegacyUris: { Default: string } & Partial<Record<string, string>>;
  RestAPIFrontOfficeUri: string;
  SubscriptionMVCUri: string;
}

declare global {
  interface Window {
    communautoAppSettings: AppSettings;
  }
}

const appSettings = window.communautoAppSettings;
export default appSettings;
